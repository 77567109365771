export function MessageRequestError(message) {
  this.message = message || "";
}
MessageRequestError.prototype = new Error();
export function handleLauderLarkinRequest(response) {
  let result = response.data;
  let { message, data } = result;
  if (message) {
    throw new MessageRequestError(message);
  }
  return data;
}
