import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
Vue.use(Vuetify);
let vuetify = new Vuetify({
  breakpoint: {
    mobileBreakpoint: "xs" // This is equivalent to a value of 960
  },
  theme: {
    options: {
      customProperties: true,
      themeCache: {
        get: () => localStorage.getItem("mostach"),
        set: (key, value) => localStorage.setItem("mostach", value)
      }
    },
    themes: {
      light: {
        primary: colors.blueGrey.darken4,
        secondary: colors.lightBlue.darken3,
        accent: colors.indigo,
        error: colors.red,
        info: colors.blue,
        success: colors.green,
        warning: colors.orange
      }
    },
    light: true
  },
  icons: {
    iconfont: "fa"
  }
});

export default vuetify;
