export default {
  luadersetLoginState(state, val) {
    state.user.isLoggedIn = val;
  },
  lauderSetSelectedAss(state, val) {
    state.selectedAssociation = val;
  },
  luaderSetMgmt(state, val) {
    state.mgmt = val;
  },
  lauderStarted(state) {
    state.started = true;
  },
  lauderSetDb(state, val) {
    state.db = val;
  },
  snackbar(state, val) {
    state.snackbar = val;
  },
  laudersetUserProfile(state, val) {
    state.user.userProfile = val;
  },
  snackAppear(state, val) {
    state.snackbar.appear = val;
  },
  loaderAppear(state, val) {
    state.loading.show = val;
  },
  loaderText(state, val) {
    state.loading.text = val;
  },
  reportType(state, val) {
    state.report.type = val;
  },
  reportIndex(state, val) {
    state.report.index = val;
  },
  reportCount(state, val) {
    state.report.assCount = val;
  },
  reportLoading(state, val) {
    state.reportsLoading = val;
  },
  searchStarted(state, val) {
    state.search.started = val;
  },
  searchText(state, val) {
    state.search.text = val;
  },
  summaryData(state, val) {
    state.report.fetchedSummaryArray = val;
  }
};
