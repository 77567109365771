export default {
  uppercase(val) {
    if (val) {
      return val[0].toUpperCase() + val.slice(1);
    }
  },
  routeName(val) {
    if (val) {
      let name = val.replace(/([A-Z])/g, " $1").trim();
      name = name.toLowerCase();
      name = this.uppercase(name);
      name = name.replace("/", "");
      return name;
    }
  },
  formatDate(val) {
    if (val) {
      let dt = new Date(val);
      return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`;
      // console.log();
      // return val.substring(0, 10);
    }
  },
  fileName(val) {
    if (val) {
      return val
        .toString()
        .replace(".", "")
        .replace(" ", "_")
        .replace(",", "");
    } else {
      return "test";
    }
  },
  rearrangeAss(arr) {
    if (arr && arr.length > 0) {
      return arr.sort((a, b) => {
        const fname = a["Client_Associations"].toLowerCase(),
          sname = b["Client_Associations"].toLowerCase();
        if (a.AllAss) return 1;
        if (b.AllAss) return 1;
        if (!isNaN(fname.charAt(0))) {
          return 1;
        }
        if (!isNaN(sname.charAt(0))) {
          return -1;
        }
        if (fname < sname) {
          return -1;
        }
        if (fname > sname) {
          return 1;
        }
        return 0;
      });
    } else {
      return [];
    }
  }
};
