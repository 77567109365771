"use strict";

import Vue from "vue";
import axios from "axios";
import storage from "@/storage";
//import { hasALocalPyServer } from "@/globals";
import { parsedClient } from "@/functions";
let config = {};

const _axios = axios.create(config);
_axios.interceptors.request.use(
  function(config) {
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

if (
  location.protocol !== "https:" &&
  (location.hostname === "clients.luederlaw.com" )
) {
  location.replace(location.href.replace("http:", "https:"));
}

let pBaseURL =  '' 
if (location.hostname === "localhost" ||location.hostname === "localhost:3333"){
 // pBaseURL= "http://localhost:3333/api"   
 pBaseURL= "https://clients.luederlaw.com/api";
}
else if (location.hostname === "172.18.124.5" ||location.hostname === "172.18.124.5:5000"){
  pBaseURL= "http://127.0.0.1:5000/api";
}
else{
  pBaseURL= "https://clients.luederlaw.com/api";
}
let lauderLarken_axios = axios.create({
  baseURL:pBaseURL,
  headers: {
    "Content-Type": "application/json;charset=UTF-8",
    "Access-Control-Allow-Origin": "*"
  },
  maxContentLength: Infinity,
  maxBodyLength: Infinity
  //adapter: cacheAdapterEnhancer(axios.defaults.adapter,{cacheFlag:"lauderlarkenaxios"})
});
// axios.interceptors.request.use(function (config) {
//   /* check the cache, if hit, then intentionally throw
//    * this will cause the XHR call to be skipped
//    * but the error is still handled by response interceptor
//    * we can then recover from error to the cached response
//    **/
//   if (requestCache.isCached(config)) {
//     const skipXHRError = new Error('skip')
//     skipXHRError.isSkipXHR = true
//     skipXHRError.request = config
//     throw skipXHRError
//   } else {
//     /* if not cached yet
//      * check if request should be throttled
//      * then open up the cache to wait for a response
//      **/
//     if (requestCache.shouldThrottle(config)) {
//       requestCache.waitForResponse(config)
//     }
//     return config;
//   }
// });
// This should be the *first* response interceptor to add
// axios.interceptors.response.use(function (response) {
//   requestCache.setCachedResponse(response.config, response)
//   return response;
// }, function (error) {
//   /* recover from error back to normality
//    * but this time we use an cached response result
//    **/
//   if (error.isSkipXHR) {
//     return requestCache.getCachedResponse(error.request)
//   }
//   return Promise.reject(error);
// });
lauderLarken_axios.interceptors.request.use(
  function(config) {
    if (!config.data) {
      config.data = {};
    }
    if (storage.getToken()) {
      config.headers["Authorization"] = `Bearer ${storage.getToken()}`;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
lauderLarken_axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    // console.log(error);
    return Promise.reject(error);
  }
);
let source = axios.CancelToken.source();
let lauderLarken = {
  login({ username, password }) {
    return lauderLarken_axios.post("verifylogin", {
      username,
      password
    });
  },
  getDash(client) {
    let now = new Date().getFullYear();
    return lauderLarken_axios.post(
      "getdash",
      {
        years: [now - 2, now - 1, now],
        client: parsedClient(client)
      },
      {
        cancelToken: source.token
      }
    );
  },
  getPayStat(client) {
    return lauderLarken_axios.post(
      "getpaystat",
      {
        client: parsedClient(client)
      },
      {
        cancelToken: source.token
      }
    );
  },
  getAssStat(client) {
    return lauderLarken_axios.post(
      "getassstat",
      {
        client: parsedClient(client)
      },
      {
        cancelToken: source.token
      }
    );
  },
  getPayTen(client) {
    return lauderLarken_axios.post(
      "getpaylasttenyears",
      {
        client: parsedClient(client)
      },
      {
        cancelToken: source.token
      }
    );
  },
  validateToken() {
    return lauderLarken_axios.get("validatetoken", {
      cancelToken: source.token
    });
  },
  getPaymentData() {
    return lauderLarken_axios.get("getpayments", {
      cancelToken: source.token
    });
  },
  getAssociationsData() {
    return lauderLarken_axios.get("getassociations", {
      cancelToken: source.token
    });
  },
  getReports(start = 0, count = 0, client = null) {
    return lauderLarken_axios.post(
      "reportCollections",
      {
        count,
        start,
        client: parsedClient(client)
      },
      {
        cancelToken: source.token
      }
    );
  },
  getAllReports(clientsStr) {
    return lauderLarken_axios.post(
      "getAllReports",
      { clientsStr },
      {
        cancelToken: source.token
      }
    );
  },
  // getClosedPayments(start = 0, count = 150) {
  //   return lauderLarken_axios.post(
  //     "closedPayArr",
  //     {
  //       count,
  //       start
  //     },
  //     {
  //       cancelToken: source.token
  //     }
  //   );
  // },
  // getClosedAssociations(start = 0, count = 150) {
  //   return lauderLarken_axios.post(
  //     "closedAssArr",
  //     {
  //       count,
  //       start
  //     },
  //     {
  //       cancelToken: source.token
  //     }
  //   );
  // },
  // getCountOfData() {
  //   return lauderLarken_axios.get("countOfClosedReports");
  // },
  // getPaymentDataAlter() {
  //   return lauderLarken_axios.get("getpaymentclosed", {
  //     cancelToken: source.token
  //   });
  // },
  // getAssociationsDataAlter() {
  //   return lauderLarken_axios.get("getassclosed", {
  //     cancelToken: source.token
  //   });
  // },
  getProfile() {
    return lauderLarken_axios.get("getprofile", {
      cancelToken: source.token
    });
  },
  getInformationData(token) {
    return lauderLarken_axios.post(
      "getinformationdata",
      {
        AssId: token
      },
      {
        cancelToken: source.token
      }
    );
  },
  logout() {
    return lauderLarken_axios.get("logoutUser");
  },
  getContacts(client) {
    return lauderLarken_axios.post(
      "getcontacts",
      { client: parsedClient(client) },
      {
        cancelToken: source.token
      }
    );
  },
  getPDF(SISID) {
    if ( process.env.NODE_ENV === "production") {
      return lauderLarken_axios.post(
        "download",
        { SISID: SISID },
        {
          cancelToken: source.token
          //,responseType: "blob"
        }
      );
    } else {
      return new Promise(resolve => {
        const results = require("@/assets/results.json");
        // const result = results[Math.floor(Math.random() * results.length)];
        resolve({ data: results[3] });
      });
    }
    // if (process.env.NODE_ENV === 'developmentremove'){
    //
    // }
    // else{

    // }

    //todo::return it back
  },
  cancel() {
    source.cancel("Operation canceled by the user.");
    source = axios.CancelToken.source();
  }
};
Vue.use(Plugin);
export default axios;
export { lauderLarken, lauderLarken_axios };
