import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    component: () => import("@/views/index"),
    redirect: { name: "lauderLaw---Home" },
    children: [
      {
        path: "",
        redirect: { name: "lauderLaw---Home" }
      },
      {
        path: "home",
        name: "lauderLaw---Home",
        component: () => import("@/views/home"),
        meta: {
          title: "Home"
        }
      },
      {
        path: "open-matters",
        name: "lauderLaw---OpenCases",
        component: () => import("@/views/open_cases"),
        meta: {
          title: "Open Matters"
        }
      },
      {
        path: "payments",
        component: () => import("@/views/payments"),
        name: "lauderLaw---payments",
        meta: {
          title: "Payments"
        }
      },
      {
        path: "reports",
        name: "lauderLaw---reports",
        component: () => import("@/views/reports"),
        meta: {
          title: "Reports"
        }
      },
      {
        path: "information",
        component: () => import("@/views/information"),
        name: "lauderLaw---information",
        meta: {
          title: "Information"
        }
      },
      {
        path: "contacts",
        component: () => import("@/views/contacts"),
        name: "lauderLaw---contacts",
        meta: {
          title: "Contacts"
        }
      },
      {
        path: "login",
        name: "lauderLaw---Login",
        component: () => import("@/views/login"),
        meta: {
          onlyWhenLogout: true,
          title: "Login"
        }
      },
      { path: "*", redirect: { name: "lauderLaw---Home" } }
    ]
  },
  { path: "**", redirect: { name: "404" } }
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
router.onError(err => console.log(err));
router.beforeEach(async (to, from, next) => {
  try {
    store.commit("loaderAppear", true);
    store.commit("loaderText", "Collecting");
    const isProjectStarted = store.getters.lauderProjectisStarted;
    if (!isProjectStarted) {
      await store.dispatch("lauderstartProject");
    }

    const isLoggedIn = store.getters.laudergetLoginState;
    const isLoginPage = to.matched.some(r => r.meta.onlyWhenLogout);
    const isPublic = to.matched.some(r => r.meta.public);
    Vue.nextTick(() => {
      document.title = to.meta.title || "Luedar Larkin & Hunter";
    });
    if (!isLoggedIn && !isLoginPage && !isPublic) {
      return next({ name: "lauderLaw---Login" });
    } else if (isLoggedIn && isLoginPage) {
      return next({ name: "lauderLaw---Home" });
    } else {
      return next();
    }
  } catch (e) {
    // console.log(e);
  }
});
router.afterEach(() => {
  store.commit("loaderAppear", false);
});
export default router;
