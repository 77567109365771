import Vue from "vue";
import "./plugins/axios";
import App from "./App";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@fortawesome/fontawesome-free/css/all.css";
import global from "@/config/global";
// import VueSocketIO from "vue-socket.io";
// Vue.use(
//     new VueSocketIO({
//         debug: true,
//         connection: "http://dcc.3545consulting.com:8000",
//         vuex: {
//             store,
//             actionPrefix: "SOCKET_",
//             mutationPrefix: "SOCKET_"
//         }
//     })
// );
Vue.config.productionTip = false;
Vue.config.errorHandler = (error, vm, info) => {
  console.log(error, vm, info);
};
Vue.config.warnHandler = (error, vm, info) => {
  console.log(error, vm, info);
};
Vue.config.devtools = process.env.NODE_ENV !== "production";
Vue.config.debug = process.env.NODE_ENV !== "production";
Vue.config.performance = process.env.NODE_ENV !== "production";
// Vue.config.silent = true;
Vue.mixin(global);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
// export default app;
