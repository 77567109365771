export default {
  snackbar(state) {
    return state.snackbar;
  },
  loading(state) {
    return state.loading;
  },
  customToken(state, { lauderGetSelectedAss }) {
    return lauderGetSelectedAss.Client_Associations;
  },
  lauderGetSelectedAss(state) {
    return state.selectedAssociation;
  },
  laudergetdb(state) {
    return state.db;
  },
  laudergetLoginProfile(state) {
    return state.user.userProfile;
  },
  lauderProjectisStarted(state) {
    return state.started;
  },
  laudergetLoginState(state) {
    return state.user.isLoggedIn;
  },
  isMgmt(state) {
    return state.mgmt;
  },
  report(state) {
    return state.report;
  },
  search(state) {
    return state.search;
  },
  summaryData(state) {
    return state.report.fetchedSummaryArray;
  }
};
