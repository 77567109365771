export default {
  started: false,
  user: {
    isLoggedIn: false,
    userProfile: {},
    isStarted: false
  },
  selectedAssociation: {},
  report: {
    type: 3,
    index: 0,
    fetchedSummaryArray: [],
    assCount: 0
  },
  search: {
    started: false,
    text: null,
    highlighted: false
  },
  mgmt: false,
  db: "",
  snackbar: {
    appear: false,
    text: "",
    timeout: -1,
    color: "danger"
  },
  loading: {
    show: false,
    text: ""
  }
};
