<template>
  <div><loader /> <router-view /></div
></template>
<script>
export default {
  name: "App",
  components: {
    Loader: () => import("@/components/loader")
  }
};
</script>
