import { getChildRoutes, keyMatcher } from "@/functions";
export default {
  // moneyFormat(labelValue) {
  //   // Nine Zeroes for Billions
  //   try {
  //     if (!labelValue) return `$0`;
  //     let index = null,
  //       str = labelValue;
  //     if (str.toString().includes(".")) {
  //       index = str.toString().split(".");
  //       str = index[0];
  //       index = index[1];
  //     }
  //     str = str.toString().replace("$", "");
  //     let result =
  //       "$" +
  //       str
  //         .toString()
  //         .split("")
  //         .reverse()
  //         .join("")
  //         .match(/.{1,3}/g)
  //         .map(function(x) {
  //           return x
  //             .split("")
  //             .reverse()
  //             .join("");
  //         })
  //         .reverse()
  //         .join(",");
  //     if (index) {
  //       result = `${result}.${index.toString().substr(0, 2)}`;
  //     }
  //     return result;
  //   } catch (e) {
  //     console.log(e, labelValue);F
  //   }
  // },
  filterAssName(name){
    // eslint-disable-next-line no-control-regex
    return name.trim().replace(/[\u0000-\u001F\u007F-\u009F]/g, "")
  },
  getRandomColor() {
    const colors = [
      "indigo darken-4",
      "blue darken-4",
      "deep-purple darken-3",
      "purple darken-4",
      "light-blue darken-4",
      "blue-grey darken-3"
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  },
  appearToast({ text, timeout, color }) {
    this.$store.commit("snackbar", {
      text: text || "",
      timeout: timeout || 5000,
      color: color || "secondary",
      appear: true
    });
  },
  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand("copy");
      const msg = successful ? "successful" : "unsuccessful";
      this.appearToast({
        text: "Copying text command was " + msg,
        color: successful ? "success" : "error"
      });
    } catch (text) {
      this.appearToast({
        text,
        color: "error"
      });
    }
    document.body.removeChild(textArea);
  },
  copyTextToClipboard(text) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        this.appearToast({
          text: "Copied Successfully",
          color: "success"
        });
      },
      text => {
        this.appearToast({
          text,
          color: "error"
        });
      }
    );
  },
  lauderGetRoutes(login = false) {
    let mainRoutes = this.$router.options.routes[0].children,
      routes = [];
    mainRoutes.forEach(childRoute => {
      try {
        let route = {},
          filtered =
            this.$options.filters.routeName(childRoute.path) ||
            this.$options.filters.routeName(childRoute.alias);
        route = {
          label: filtered,
          name: childRoute.name,
          icon: keyMatcher(filtered)
        };
        if (
          childRoute.exportable === false ||
          ([childRoute].some(r => r.meta && r.meta.onlyWhenLogout) && !login)
        ) {
          return true;
        }
        if (childRoute.children) {
          let children = getChildRoutes(childRoute);
          route.children = [];
          children.forEach(child => {
            if (!child.props) {
              route.children.push({
                label: this.$options.filters.routeName(
                  child.path || child.alias
                ),
                name: child.name,
                icon: keyMatcher(child.path)
              });
            }
          });
        }
        if (childRoute.meta.loadTypes) {
          route.children.push({
            refresh: true,
            loadTypes: childRoute.meta.loadTypes
          });
        }
        routes.push(route);
      } catch (e) {
        return true;
      }
    });
    return routes;
    // return routes.sort((a, b) => {
    //   if (a.children) {
    //     return 1;
    //   }
    //   if (b.children) {
    //     return -1;
    //   }
    //   return 0;
    // });
  },
  parsedHtml(search, real) {
    const index = real
      .toString()
      .toLowerCase()
      .indexOf(search.toLowerCase());
    if (index > -1) {
      const real_word = real.substring(index, index + search.length);
      return real.replace(
        new RegExp(`${search}`, "ig"),
        `<span class="highlight">${real_word}</span>`
      );
    } else {
      return real;
    }
  },
  async fetchPayments() {
    //this.loading = true;
    this.appearToast({
      text: "Updating Payments...",
      color: "primary"
    });
    try {
      // await this.$store.dispatch("lauderfetchPaymentData");
      // await this.$store.dispatch("lauderfetchPaymentData", true);
      this.appearToast({
        text: "Payments Successfully fetched",
        color: "success"
      });
    } catch (e) {
      this.appearToast({ text: e, color: "red" });
    }
    //this.loading = false;
  }
  // async fetchAssociations() {
  //   // this.loading = true;
  //   this.appearToast({
  //     text: "Updating Associations...",
  //     color: "primary"
  //   });
  //   try {
  //     await this.$store.dispatch("lauderfetchAssociationData");
  //     // await this.$store.dispatch("lauderfetchAssociationData", true);
  //     this.appearToast({
  //       text: "Associations Successfully fetched",
  //       color: "success"
  //     });
  //   } catch (e) {
  //     this.appearToast({ text: e, color: "red" });
  //   }
  //   // this.loading = false;
  // }
};
