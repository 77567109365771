import Vue from "vue";
import Vuex from "vuex";
import state from "@/store/state";
import getters from "@/store/getters";
import mutations from "@/store/mutations";
import actions from "@/store/actions";
// import { vuexModule } from "zelony-indexeddb";
// import indexedDbModule from "$/vuex_modules/indexedDb";
// console.log(indexedDbModule);
Vue.use(Vuex);
const store = new Vuex.Store({
  strict: true,
  state,
  mutations,
  actions,
  getters,
  // modules:{
  //   indexedDb:vuexModule
  // },
  performance: process.env.NODE_ENV !== "production",
  devtools: process.env.NODE_ENV !== "production"
});
export default store;
