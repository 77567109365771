export const absoluteLabel = labelValue => {
  let key,num;
  if (Math.abs(Number(labelValue)) >= 1.0e9) {
    num = Math.abs(Number(labelValue)) / 1.0e9;
    key = "B";
  } else if (Math.abs(Number(labelValue)) >= 1.0e6) {
    num = Math.abs(Number(labelValue)) / 1.0e6;
    key = "M";
  } else if (Math.abs(Number(labelValue)) >= 1.0e3) {
    num = Math.abs(Number(labelValue)) / 1.0e3;
    key = "K";
  } else {
    num = Math.abs(Number(labelValue));
    key = "";
  }
  return { num, key };
};
export function formatDate(date, joiner = "/") {
  let d;
  if (date instanceof Date) {
    d = date;
  } else {
    d = new Date(date);
  }
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  // if (month.length < 2) month = "0" + month;
  // if (day.length < 2) day = "0" + day;

  return [month, day, year].join(joiner);
}
export const doubleRound = labelValue => {
  const { num, key } = absoluteLabel(labelValue);
  return (Math.round(num * 100) / 100).toString().substr(0, 3) + key;
};
export function GetOnlyOpened(arr) {
  return arr.filter(r =>
    require("@/assets/status.json").some(x => x.name === r["Status"])
  );
}
export function orderPayByDate(payments) {
  return payments.sort((a, b) => {
    let ad = new Date(a["Payment Date"] || a["Payment_Date"]),
      bd = new Date(b["Payment Date"] || b["Payment_Date"]);
    if (ad < bd) {
      return 1;
    } else if (ad > bd) {
      return -1;
    } else {
      return 0;
    }
  });
}
export const processMoney = val => {
  let result;
  if (typeof val === typeof 0) {
    result = parseFloat(val);
  } else if (!isNaN(val) || !val) {
    result = parseFloat("0");
  } else {
    let cal = val.replace("$", "").replace(" ", "");
    cal = cal.replace(",", "");
    result = parseFloat(cal);
  }
  return result;
};
export const getByAss = (ass, arr) => {
  return arr.filter(
    r => r["Client_Associations"] === ass["Client_Associations"]
  );
};
export const customFilterArr = (arr, key) => {
  try {
    let arrEle = [];
    if (!arr || typeof arr !== typeof [] || arr.length === 0) return [];
    arr.forEach((el, indexOfel) => {
      if (el[key]) {
        var found = false,
          index = 0;
        if (
          arrEle.some(
            r => r.id.toLowerCase() === el[key].toString().toLowerCase()
          )
        ) {
          found = true;
          index = arrEle.findIndex(
            r => r.id.toLowerCase() === el[key].toString().toLowerCase()
          );
        }
        if (found) {
          arrEle[index].count++;
          arrEle[index].ref.push(indexOfel);
          arrEle[index].idRef = [el.id];
        } else {
          arrEle.push({
            value: key,
            id: el[key],
            count: 1,
            ref: [indexOfel],
            idRef: [el.id]
          });
        }
      }
    });
    return arrEle;
  } catch (e) {
    // console.log(e);
    return e;
  }
};
export const moneyFormat = labelValue => {
  // Nine Zeroes for Billions
  try {
    if (!labelValue) return `$0`;
    let index = null,
      str = labelValue;
    if (str.toString().includes(".")) {
      index = str.toString().split(".");
      str = index[0];
      index = index[1];
    }
    str = str.toString().replace("$", "");
    str = str.replace(",", "");
    if (!str) {
      str = "0.00";
    }
    let result =
      "$" +
      str
        .toString()
        .split("")
        .reverse()
        .join("")
        .match(/.{1,3}/g)
        .map(function(x) {
          return x
            .split("")
            .reverse()
            .join("");
        })
        .reverse()
        .join(",");
    if (index) {
      result = `${result}.${index.toString().substr(0, 2)}`;
    }
    return result;
  } catch (e) {
    console.log(e, labelValue);
  }
};
export const getChildRoutes = route => {
  let routes = [];
  if (route.children && route.children.length > 0) {
    route.children.forEach(child => {
      if (child.children && child.children.length > 0) {
        child.children = getChildRoutes(child);
      }
      routes.push(child);
    });
  }
  return routes;
};
export const keyMatcher = key => {
  let retIcon = "home",
    keyMatcherIcons = {
      hashtag: ["ID"],
      signature: ["full_name"],
      at: ["User_name"],
      key: ["User_Password"],
      "chart-pie": ["dashboard"],
      "file-contract": ["reports", "file-invoice"],
      allCollections: ["folder"],
      "sticky-note": ["lastNote", "summaryWNote"],
      "folder-open": ["open", "open-matters"],
      "file-alt": ["summaryReport"],
      "user-circle": ["profile"],
      "money-bill-alt": ["payments"],
      users: ["owners"],
      search: ["search"],
      "address-book": ["contacts"],
      "info-circle": ["information"],
      "door-open": ["login"],
      building: ["associations"]
    };
  for (const icon in keyMatcherIcons) {
    let keys = keyMatcherIcons[icon] || [];
    if (keys.includes(key.toLowerCase())) {
      retIcon = icon;
      return icon;
    }
  }
  return retIcon;
};
export const dateFormat = (date = new Date()) => {
  let m = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ][date.getMonth()];
  let d = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
  let y = date.getFullYear();
  return `${m} ${d} ${y}`;
};
export const searchInObjects = (objects, search, keys = []) => {
  let results = [];
  for (var i = 0; i < objects.length; i++) {
    if (searchInObject(objects[i], search, keys)) {
      results.push(objects[i]);
    }
  }
  return results;
};
export const searchInObject = (object, search, keys = []) => {
  for (const key in object) {
    if (
      keys &&
      keys.length > 0 &&
      !keys.some(r => r.toLowerCase() === key.toLowerCase())
    )
      continue;
    let val = object[key] ? object[key].toString().toLowerCase() : false;
    if (val && val.indexOf(search.toString().toLowerCase()) !== -1) {
      return true;
    }
  }
  return false;
};
export const parsedClient = client => client && client.replace(/'/g, "''''");
window.moneyFormat = moneyFormat;
